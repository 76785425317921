import React from 'react';

import {
  Layout,
  HeroGeneric,
  ConsultingBanner,
  ContactFormBanner,
  SEO,
  ConsultingBlogBanner,
} from '../components';

export default function Consulting(props) {
  return (
    <Layout {...props}>
      <SEO title="Consulting" pathname={props.location.pathname} />
      <HeroGeneric title="Consulting" />
      <ConsultingBanner />
      <ConsultingBlogBanner />
      <ContactFormBanner />
    </Layout>
  );
}
